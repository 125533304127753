import React from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { formatISO9075 } from 'date-fns';

import { AdminUserService, UserService } from '@asteria/backend-utils-services';

import { getAvatarLabel } from './utils';

const Message = React.memo(function Message(props) {
	const { content, createdAt } = props;

	const createdBy = useQuery({
		queryKey: ['chats', 'createdBy', props.createdBy],
		queryFn: async () => {
			if (props.createdBy?.type === 'UserToken') {
				return UserService.user.fetchOne({
					id: props.createdBy?.id,
					fields: '_id firstName lastName username',
				});
			}

			return AdminUserService.adminUser.fetchOne({
				id: props.createdBy?.id,
				fields: '_id firstName lastName username',
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return (
		<div
			className={classNames('flex gap-2 items-end', {
				'flex-row-reverse': props.createdBy.type === 'AdminToken',
			})}
		>
			<div className="h-10 w-10 rounded-full border border-zinc-200 bg-gray-100 flex-none flex items-center justify-center text-sm font-bold">
				{getAvatarLabel(createdBy?.data, props.createdBy.type)}
			</div>
			<div
				className={classNames('flex flex-col', {
					'items-end': props.createdBy.type === 'AdminToken',
				})}
			>
				<p className="text-base font-medium">
					{[
						[createdBy?.data?.firstName, createdBy?.data?.lastName]
							.filter(Boolean)
							.join(' '),
						createdBy?.data?.username,
					]
						.filter(Boolean)
						.join(' - ')}
				</p>
				<p className="text-sm text-zinc-400">
					{formatISO9075(createdAt)}
				</p>
				<div
					className="px-4 py-2 rounded bg-gray-100 w-fit border border-zinc-200"
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</div>
		</div>
	);
});

export default Message;
